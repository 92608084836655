<template>
  <v-container class="pa-3">
    <h1 class="primary--text">Statistiques</h1>
    <v-tabs v-model="tab">
      <v-tab>
        Indicateurs DEAL
      </v-tab>
      <v-tab>
        Financements perçus
      </v-tab>
      <v-tab>
        Financements engagés
      </v-tab>
      <v-tab>
        Dossiers par état
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row>
          <v-col cols="6" md="4" lg="3">
            <v-row justify="space-between" align="center" no-gutters>
              <v-select label="Année" class="mb-2 px-4" @change="loadStats" v-model="filters.year"
                        :items="active_years"></v-select>
              <v-btn icon color="red" @click="clearFilter">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-card color="grey" class="ma-4 pa-3 text-center">
              <h2 class="white--text" v-if="loading === false">Budget moyen travaux (en k€)
                <br>{{ arrondirKeuros(stats.budget_moyen_travaux) }}</h2>
              <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card to="/dossier?arrete=absent" color="blue" class="ma-4 pa-3 text-center">
              <h2 class="white--text" v-if="loading === false">Apport personnel moyen (en k€)
                <br>{{ arrondirKeuros(stats.apport_personnel_moyen) }}</h2>
              <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="primary" class="ma-4 pa-3 text-center">
              <h2 class="white--text" v-if="loading === false">Indice de dégradation moyen
                <br>{{ stats.indice_degradation_moyen.toFixed(2) }}</h2>
              <v-progress-circular indeterminate v-else color="white" class="justify-center align-center"/>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <FinancementsPercus/>
      </v-tab-item>
      <v-tab-item>
        <FinancementsEngages/>
      </v-tab-item>
      <v-tab-item>
        <DossierParEtat/>
      </v-tab-item>
    </v-tabs-items>
  </v-container>

</template>

<script>
import FinancementsPercus from "@/views/FinancementsPercus.vue";
import FinancementsEngages from "@/views/FinancementsEngages.vue";
import DossierParEtat from "@/views/DossierParEtat.vue";
import {arrondirKeuros} from "../util";

export default {
  name: "Statistiques",
  components: {DossierParEtat, FinancementsEngages, FinancementsPercus},
  async mounted() {
    this.stats = await this.$store.dispatch("stats/fetchIndicateursDEAL", this.filters.year);
    this.loading = false;
  },
  data() {
    return {
      tab: 0,
      filters: {
        year: null,
      },
      loading: true,
      stats: {
        budget_moyen_travaux: 0.00,
        apport_personnel_moyen: 0.00,
        indice_degradation_moyen: 0.00,
      }
    }
  },
  methods: {
    arrondirKeuros,
    clearFilter() {
      this.filters.year = null;
    },
    async loadStats() {
      this.loading = true;
      this.stats = await this.$store.dispatch("stats/fetchIndicateursDEAL", this.filters.year);
      this.loading = false;
    }
  },
  computed: {
    active_years() {
      let activeYears = [];
      let year = new Date().getFullYear()
      for (var i = 2020; i < year; i++) {
        activeYears.push(i);
      }
      return activeYears;
    }
  }
}
</script>