<template>
  <canvas :height="height" :width="width" id="financements-percus-graph"></canvas>
</template>
<script>
import {SOLUTIA} from "@/constants/Colors";
import {Chart} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {arrondirKeuros} from "@/util";

export default {
  name: "FinancementsPercusCTMGraph",
  props: {
    data: Array,
    height: {
      type: Number,
      default: 600,
    },
    width: {
      type: Number,
      default: 600,
    }
  },
  mounted: async function () {
    let statusFetchStatus = this.$store.getters["dossier/statuts/statut_fetch_status"];
    if (statusFetchStatus === "INITIAL" || statusFetchStatus === "ERROR") {
      await this.$store.dispatch("dossier/statuts/getStatuts");
    }
    // eslint-disable-next-line no-unused-vars
    const chartData = {
      label: "Financements perçus CTM",
      labels: this.data.map(data_of_year => data_of_year.year),
      datasets: [
          {
          label: "TOTAL Financement Perçus CTM",
          data: this.data.map(data_of_year => arrondirKeuros(data_of_year.total_paye_ctm)),
          datalabels: {
            color: '#ffffff',
            font: {
              size: 16
            }
          },
          backgroundColor: SOLUTIA
        },
        {
          label: "TOTAL Financement Restant à demander CTM",
          data: this.data.map(data_of_year => arrondirKeuros(data_of_year.reste_a_payer_ctm_non_notifie)),
          datalabels: {
            color: '#ffffff',
            font: {
              size: 16
            }
          },
          backgroundColor: SOLUTIA
        },
        {
          label: "TOTAL Financements à Percevoir CTM",
          data: this.data.map(data_of_year => arrondirKeuros(data_of_year.reste_a_payer_ctm)),
          datalabels: {
            color: '#ffffff',
            font: {
              size: 16
            }
          },
          backgroundColor: SOLUTIA
        },
      ],
    }
    // eslint-disable-next-line no-unused-vars
    const config = {
      title: {
        display: true,
        text: "Financements perçus CTM",
        fontSize: 16,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            drawBorder: false,
          }
        }],
        xAxes: [{
          gridLines: {
            display: false,
          }
        }]
      },

    }
    let that = this;
    this.$nextTick(function () {
      const ctx = document.getElementById('financements-percus-graph');
      that.chart = new Chart(ctx, {
        plugins: [ChartDataLabels],
        type: "bar",
        //...defaultData
        data: JSON.parse(JSON.stringify(chartData)),
        options: JSON.parse(JSON.stringify(config)),
      });
    })
  },
  data: function () {
    return {
      chart: null
    }
  },
  computed: {
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"];
    },
  },
  methods: {}
}
</script>

<style scoped>

</style>