<template>
  <v-row class="pa-4">
    <v-col cols="12">
      <h1 class="primary--text text-center mb-4">Financements perçus (en k€)</h1>
      <v-row v-if="loading === false">
        <v-col cols="3">
          <FinancementsPercusCTMGraph :data="financement_percus"/>
        </v-col>
        <v-col cols="3">
          <FinancementsPercusDEALGraph :data="financement_percus"/>
        </v-col>
        <v-col cols="3">
          <FinancementsPercusTotalGraph :data="financement_percus"/>
        </v-col>
      </v-row>
      <v-simple-table class="mb-2">
        <template v-slot:default>
          <thead>
            <tr class="primary">
              <th class="white--text font-weight-bold">Année d'arrêté</th>
              <th class="white--text font-weight-bold">Budget Travaux et MOE</th>
              <th class="white--text font-weight-bold">Total financements demandes</th>
              <th class="white--text font-weight-bold">Total des financements perçus</th>
              <th class="white--text font-weight-bold">Total des financements à percevoir</th>
              <th class="white--text font-weight-bold">Reste à payer DEAL</th>
              <th class="white--text font-weight-bold">Reste à payer DEAL demandé</th>
              <th class="white--text font-weight-bold">Reste à payer CTM</th>
              <th class="white--text font-weight-bold">Reste à payer CTM notifié</th>
              <th class="white--text font-weight-bold">Reste à payer CTM non notifié</th>
              <th class="white--text font-weight-bold">Reste à payer apport personnel</th>
            </tr>
          </thead>
          <tbody v-if="loading === false">
            <tr @click="year = data.year" v-for="data in financement_percus" :key="data.year">
              <td>{{data.year}}</td>
              <td>{{formatNumbers(data.budget_travaux_moe)}}</td>
              <td>{{formatNumbers(data.travaux_moe_demandes)}}</td>
              <td>{{formatNumbers(data.travaux_moe_payees)}}</td>
              <td>{{formatNumbers(data.travaux_moe_non_payees)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_deal)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_deal_demande)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_ctm)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_ctm_notifie)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_ctm_non_notifie)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_apport)}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-simple-table v-if="year !== null">
        <template v-slot:default>
          <thead>
            <tr class="primary">
              <th class="white--text font-weight-bold">Nom du bénéficiaire</th>
              <th class="white--text font-weight-bold">Budget Travaux et MOE</th>
              <th class="white--text font-weight-bold">Total financements demandes</th>
              <th class="white--text font-weight-bold">Total des financements perçus</th>
              <th class="white--text font-weight-bold">Total des financements à percevoir</th>
              <th class="white--text font-weight-bold">Reste à payer DEAL</th>
              <th class="white--text font-weight-bold">Reste à payer DEAL demandé</th>
              <th class="white--text font-weight-bold">Reste à payer CTM</th>
              <th class="white--text font-weight-bold">Reste à payer CTM notifié</th>
              <th class="white--text font-weight-bold">Reste à payer CTM non notifié</th>
              <th class="white--text font-weight-bold">Reste à payer apport personnel</th>
            </tr>
          </thead>
          <tbody v-if="loading === false">
            <tr v-for="data in detailsForYear" :key="data.fullname">
              <td>{{data.fullname}}</td>
              <td>{{formatNumbers(data.budget_travaux_moe)}}</td>
              <td>{{formatNumbers(data.travaux_moe_demandes)}}</td>
              <td>{{formatNumbers(data.travaux_moe_payees)}}</td>
              <td>{{formatNumbers(data.travaux_moe_non_payees)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_deal)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_deal_demande)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_ctm)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_ctm_notifie)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_ctm_non_notifie)}}</td>
              <td>{{formatNumbers(data.reste_a_payer_apport)}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-progress-circular v-if="loading" color="primary" indeterminate class="center-block"/>
    </v-col>
  </v-row>
</template>
<script>
import {arrondirKeuros} from "@/util";
import FinancementsPercusCTMGraph from "@/views/FinancementsPercusGraph.vue";
import FinancementsPercusDEALGraph from "@/views/FinancementsPercusDEALGraph.vue";
import FinancementsPercusTotalGraph from "@/views/FinancementsPercusTotalGraph.vue";

export default {
  name: 'FinancementsPercus',
  components: {FinancementsPercusTotalGraph, FinancementsPercusDEALGraph, FinancementsPercusCTMGraph},
  async mounted() {
    this.financement_percus = await this.$store.dispatch("stats/fetchFinancementsPercus");
    this.loading = false;
  },

  data() {
    return {
      loading: true,
      financement_percus: [],
      year: null,
    }
  },
  methods: {
    formatNumbers(value) {
      return arrondirKeuros(value);
    },
  },
  computed: {
    detailsForYear() {
      if(this.year === null) return [];
      return this.financement_percus.find(data => data.year === this.year).details;
    }
  }
}
</script>