<template>
  <v-row class="pa-4">
    <v-col cols="12">
      <h1 class="primary--text text-center mb-4">Dossiers par état</h1>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr class="primary">
              <th class="white--text font-weight-bold">Année d'arrêté</th>
              <th class="white--text font-weight-bold">Nombre de dossiers arrêtés</th>
              <th class="white--text font-weight-bold">Budget d'opération global (en k€)</th>
              <th class="white--text font-weight-bold">Nombre de dossiers livrés</th>
              <th class="white--text font-weight-bold">Nombre de dossiers en travaux</th>
              <th class="white--text font-weight-bold">Nombre de dossiers en stock</th>
            </tr>
          </thead>
          <tbody v-if="loading === false">
            <tr v-for="data in dossiers_par_etat" :key="data.year">
              <td>{{data.year}}</td>
              <td>{{data.nbre_operations_engages}}</td>
              <td>{{arrondirKeuros(data.budget_operation_engages)}}</td>
              <td>{{data.nbre_operations_livres}}</td>
              <td>{{data.nbre_operations_en_travaux}}</td>
              <td>{{data.nbre_operations_en_attente}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-progress-circular v-if="loading" color="primary" indeterminate class="center-block"/>
    </v-col>
  </v-row>
</template>
<script>
import {arrondirKeuros} from "@/util";

export default {
  name: 'DossierParEtat',
  async mounted() {
    this.dossiers_par_etat = await this.$store.dispatch("stats/fetchDossierParEtat");
    this.loading = false;
  },

  data() {
    return {
      loading: false,
      dossiers_par_etat: [],
    }
  },
  methods: {
    formatNumbers(value) {
      return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
        value,
      );
    },
    arrondirKeuros
  }
}
</script>